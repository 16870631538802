import { config } from './../../../config/pages-config';
import { LOGIN_URL } from '@vfde-fe/ngx-lib/core';

/** ACCOUNT_TYPES */
export const ACCOUNT_TYPES = {
    cableAccount: {
        name: 'cable',
        technicalName: 'de.vodafone.account.Cable',
    },
    mobileAccount: {
        name: 'mobile',
        technicalName: 'de.vodafone.account.Mobile',
    },
    dslAccount: {
        name: 'dsl',
        technicalName: 'de.vodafone.account.DSL',
    },
    unityMedia: {
        name: 'UM',
        technicalName: 'de.vodafone.account.UM',
    },
};
/** CONTRACT_TYPES_LIST */
export const CONTRACT_TYPES_LIST = {
    KAY: 'kay',
    BART: 'bart',
    MARIE: 'marie',
    ANNA: 'anna',
    PETER: 'peter',
    PAUL: 'paul',
    RALF: 'ralf',
    MARK: 'mark',
    EMMA: 'emma',
    MARTIN: 'martin',
    DONALD: 'donald',
    DELLA: 'della',
    DAISY: 'daisy',
};
/** DOCUMENT_LANDING_URL_TYPES */
export const DOCUMENT_LANDING_URL_TYPES = {
    '/legitimation': 'legitimation',
    '/nachweis-ma-rabatt': 'nachweis-ma-rabatt',
    '/jetztwechseln': 'jetztwechseln',
    '/business/legitimation': 'business/legitimation',
    '/zuhause-legi': 'zuhause-legi',
};

/** AuthStatus */
export const AuthStatus = {
    notIsAuth: 'notIsAuth',
    isAuth: 'isAuth',
};

/** MODULE_NAMES */
export const MODULE_NAMES = {
    registrationModule: 'registration',
    loginModule: 'login',
    forgetPassword: 'forgetPassword',
    accountManagement: 'accountManagement',
    forgetUsername: 'forgetUsername',
    firstLoginModule: 'firstLogin',
    checkEmailValidation: 'checkEmailValidation',
    activatePhoneNumber: 'activatePhoneNumber',
    validateMtan: 'validateMtanModule',
    acceptUserInvitationModule: 'acceptUserInvitation',
    solsticeFirstLogin: 'solsticeFirstLogin',
};

/** USERNAME_OR_PASSWORD_OUTCOME_TRACKING */
export const USERNAME_OR_PASSWORD_OUTCOME_TRACKING = {
    USERNAME: 'changed_username',
    PASSWORD: 'changed_password',
    USERNAME_AND_PASSWORD: 'changed_username+pw',
};

/** ACCEPT_INVITATION_SESSION_STORAGE */
export const ACCEPT_INVITATION_SESSION_STORAGE = {
    ACCEPT_INVITATION: 'acceptInvitation',
};

/** INVITATION_TYPE */
export const INVITATION_TYPE = {
    UM: 'um',
    SOLSTICE: 'solstice-delegation',
};

/** INVITATION_TYPE */
export const INVITATION_VALUES = {
    'um': 'unitymedia',
    'solstice-delegation': 'solstice',
};

/** ONB_Chooser_Step */
export const ONB_Chooser_Step = {
    login: 'login',
    registration: 'registration',
};
/** ONB_Chooser_Step */
export const ACCEPT_INVITATION_TYPE = {
    ONB: 'onb',
};

/** ACCEPT_USER_INVITATION */
export const ACCEPT_USER_INVITATION = {
    chooser: 'chooser',
    submitPersonalData: 'submitPersonalData',
    submitContactData: 'submitContactData',
    submitUserData: 'submitUserData',
    setSecurityQuestions: 'setSecurityQuestions',
    reviewData: 'reviewData',
    confirmation: 'confirmation',
};

/** PROCESS_MODULE_KEY */
export const PROCESS_MODULE_KEY = {
    REGISTRATION: 'com.uxpsystems.mint.vodafone.process.frontend.registration.v1.0',
    FORGET_USERNAME: 'com.uxpsystems.mint.vodafone.process.frontend.usernameForgotten.v1.0',
    FORGET_PASSWORD: 'com.uxpsystems.mint.vodafone.process.frontend.resetPassword.v1.0',
    RESET_PASSWORD_TOKEN: 'com.uxpsystems.mint.vodafone.process.frontend.resetPasswordConfirm.v1.0',
    ValidateMsisdnViaSms: 'com.uxpsystems.mint.vodafone.process.frontend.validateMsisdnViaSms.v1.0',
    ACTIVATE_PHONE_NUMBER: 'com.uxpsystems.mint.vodafone.process.frontend.manageTAN.v1.0',
    ACCEPT_USER_INVITATION: 'com.uxpsystems.mint.vodafone.process.frontend.acceptUserInvitation.v1.0',
    SOLSTICE_FIRST_LOGIN: 'com.uxpsystems.mint.vodafone.process.frontend.solsticeFirstLogin.v1.0',
};

/** Mobile_CONTRACT_TYPES */
export const Mobile_CONTRACT_TYPES = {
    EK: 'EK',
    KD: 'KD',
    TN: 'TN',
    callYa: 'callYa',
    MMO: 'MMO',
};

/** CONTRACT_TYPES_URL */
export const CONTRACT_TYPES_URL = {
    TN: 'de.vodafone.subscription.mobile.AccessSubscription',
};

/** REGISTRATION_URL */
export const REGISTRATION_URL = {
    FORUM: 'https://opweb3.vfd2-testnet.de' + LOGIN_URL.url + '?targetURL=https%3A%2F%2Fforum-stage.vfd2-testnet.de',
    FORUM_PROFILE: 'https://opweb3.vfd2-testnet.de' + LOGIN_URL.url + '?targetURL=https%3A%2F%2Fforum-stage.vfd2-testnet.de/t5/user/myprofilepage/tab/personal-profile',
};

/** REGISTRATION_TYPES */
export const REGISTRATION_TYPES = {
    tempAccount: {
        name: 'tempAccount',
    },
    contract: {
        name: 'contract',
    },
    noContract: {
        name: 'noContract',
    },
    loginForum: {
        name: 'loginForum',
    },
    registerForum: {
        name: 'registerForum',
    },
    forum: {
        name: 'forum',
    },
    forumLogin: {
        name: 'forumLogin',
    },
    emailLogin: {
        name: 'email',
    },
};

/** lastTrailCustomError_MobileContract */
export const lastTrailCustomError_MobileContract = {
    status: '400',
    operationError: {
        code: 'wrong_crdentials',
    },
};

/** ERROR_CODES */
export const ERROR_CODES = {
    REGISTRATION: {
        CONTRACT_TEMPORARY_LOCKED: 'contract-temporary-locked',
    },
    FIRST_LOGIN: {
        CREDENTIALS_EXPIRED: 'credentials-expired',
        FIRSTLOGIN_ALREADY_FINISHED: 'firstLogin-already-finished',
        USERNAME_NOT_ALLOWED: 'username-not-allowed',
    },
};

/** AUTHENTICATION_REQUIRED_ERORR */
export const AUTHENTICATION_REQUIRED_ERORR = {
    status: '401',
    error: 'authentication-required',
};

/** CONTRACT_TYPES */
export const CONTRACT_TYPES = {
    mobile: {
        name: 'mobile',
    },
    dsl: {
        name: 'dsl',
    },
    cable: {
        name: 'cable',
    },
    fixednet: {
        name: 'fixednet',
    },
    unityMedia: {
        name: 'unitymedia',
    },
    solstice: {
        name: 'solstice',
    },
};

/** SECURITY_QUESTION_TYPES */
export const SECURITY_QUESTION_TYPES = {
    update: 'update',
    reuse: 'reuse',
    delete: 'delete',
};

/** USER_ACCESS_LIST_KEY */
export const IDLENESS_CONFIG_TIME = 900000;

/** USER_SESSION_KEY_PREFIX */
export const USER_SESSION_KEY_PREFIX = {
    data: 'ecare-LocalStore.data.',
    keys: 'ecare-LocalStore.keys',
};
/** USER_PROFILE_DETAILS */
export const USER_PROFILE_DETAILS = {
    idleness: 'idleness',
    ACL: 'ACL',
    userID: 'userID',
    mboIdsObject: 'mboIdsObject',
    singleCardFlag: 'singleCardFlag',
    singleContractFlag: 'singleContractFlag',
};

/** USER_ACCESS_LIST_KEY */
export const USER_ACCESS_LIST_KEY = 'ACL';

/** ACCESS_LEVELS_LIST */
export const ACCESS_LEVELS_LIST = {
    ACCOUNT: 'account',
    CONTRACT: 'contract',
    SUBSCRIPTION: 'subscription',
    CONTRACT_OR_SUBSCRIPTION: 'contractOrSubscription',
};

/** LOGIN_CONFIG */
export const LOGIN_CONFIG = {
    CLIENT_TYPE: 'Portal',
};

/** ACTORS */
export const ACTORS = {
    kay: 'kay',
    marie: 'marie',
    peter: 'peter',
    paul: 'paul',
    mark: 'mark',
    anna: 'anna',
    emma: 'emma',
    ralf: 'ralf',
    martin: 'martin',
    bart: 'bart',
    eric: 'eric',
    donald: 'donald',
    ute: 'ute',
};

/** CONTRACTNAMES */
export const CONTRACTNAMES = {
    postpaid: 'Mobilfunk-Vertrag',
    prepaid: 'CallYa / Prepaid',
    dsl: 'DSL Vertrag',
};

/** CONTRACTTYPES */
export const CONTRACTTYPES = {
    postPaid: 'post-paid',
    prePaid: 'pre-paid',
    fixedNet: 'fixedNet',
    cable: 'cable',
};

/** LOG_LEVELS */
export const LOG_LEVELS = {
    OFF: 0,
    ERROR: 1,
    WARN: 2,
    INFO: 3,
    DEBUG: 4,
    LOG: 5,
};

/** email status for NIL services */
export const EMAIL_STATUS = {
    verified: 'c',
    notVerified: 'u',
};

/** FORGET_PASSWORD_CHOOSE_TYPE */
export const FORGET_PASSWORD_CHOOSE_TYPE = {
    vodafoneContratTypeName: 'vodafone-contract',
    cableContratTypeName: 'cable-contract',
    cableContratTypeId: 'cable-contract',
    mtan: 'mTAN',
    email: 'email',
    submitSecurityQuestions: 'submit-security-questions',

};

/** FORGET_PASSWORD_CONTRACT_TYPES */
export const FORGET_PASSWORD_CONTRACT_TYPES = {
    CABLE_CONTRACT: 'cable-contract',
    MOBILE_CONTRACT: 'mobile-contract',
    DSL_CONTRACT: 'dsl-contract',
    SUBMIT_SECURITY_QUESTIONS: 'submit-security-questions',
};

/** FORGET_PASSWORD_ERROR_CODES */
export const FORGET_PASSWORD_ERROR_CODES = {
    WRONG_PASSWORD: 'wrong-password',
    REMAINING_TRIES: 'add-contract-remaining-tries',
    LAST_TRIAL: 'wrong-password-last-trial',
    WRONG_ACTIVATION_CODE: 'activation-code-not-valid',
    WRONG_ACTIVATION_CODE_LAST_TRAIL: 'activation-code-not-valid-last-trial',
    NOT_ALLOWED: 'not-allowed',
    RESEND_MAXED_OUT: 'max-resend-reached',

};

/** FORGET_USERNAME_CHOOSE_METHOD */
export const FORGET_USERNAME_CHOOSE_METHOD = {
    MTAN: 'mTAN',
    EMAIL: 'email',
};

/** CONTRACT_TYPE */
export const CONTRACT_TYPE = {
    DSL: 'dsl',
    MOBILE: 'mobile',
    CABLE: 'cable',
};

/** FORGET_USERNAME_EROOR_CODE */
export const FORGET_USERNAME_EROOR_CODE = {
    ALTERNATIVE_PROCESS_TERMINATE: 'alternative-process-terminate',
    USED_MULTIPLE_TIMES: 'used-multiple-times',
    EMAIL_ADDRESS_NOT_VALIDATED: 'email-address-not-validated',
    INVALID_CUSTOMER_NUMBER: 'Invalid-customerNumber',
    INVALID_CUSTOMER_NUMBER_LAST_TRAIL: 'Invalid-customerNumber-last-trail',
    INVALID_CUSTOMER_NUMBER_BLOCKED: 'Invalid-customerNumber-blocked',
    WRONG_CREDENTIALS: 'wrong-credentials',
    WRONG_CREDENTIALS_LAST_TRIES: 'wrong-crdentials-last-trail',
    ADD_CONTRACT_REMAINING_TRIES: 'add-contract-remaining-tries',
};

/** LOGOUT_IDEL_CONF */
export const LOGOUT_IDEL_CONF = {
    IDEL_TIME: 20,
    TIMEOUT_TIME: 1800,
    PING_INTERVAL: 15,
    TARGET_URL_ID: 'targetURL',
    LOGOUT_FORM_ID: 'logoutform',
};

/** AUTHENTICATION_ERROR_CODE */
export const AUTHENTICATION_ERROR_CODE = {
    MINT_AUTHENTICATION_REQUIRED: 'authentication-required',
    NIL_AUTHENTICATION_REQUIRED: 'NIL00A001',
    ACTION_BLOCKED_ERROR :{
        status: '401',
        error: 'user-profile-locked',
    },
    PROCCESS_TERMINATED_ERROR:{
        status:'400',
        error:'process-terminated-with-too-many-retries',
    },
    MAX_RESEND_REACHED:{
        status:'403',
        error:'max-resend-reached',
    },
};

/** AUTHORIZATION_ERROR_CODE */
export const AUTHORIZATION_ERROR_CODE = {
    SECURITY_CHALLENGE_FAILED: 'security-challenge-failed',
};

/** AUTHENTICATION_WHITELIST */
export const AUTHENTICATION_WHITELIST: Array<string> = [MODULE_NAMES.loginModule];

/** PAGE_TYPE */
export const PAGE_TYPE = {
    ONE_TIME_TRANSACTION: 'oneTimeTransaction',
};

/** DISABLE_DEEP_LINKS_WHITE_LIST */
export const DISABLE_DEEP_LINKS_WHITE_LIST: string[] = [
    config.generalError.route,
    config.login.enterContactData.route,
    config.login.updateEmail.route,
];

/** TRIM_TYPES */
export const TRIM_TYPES = {
    TRIM: 'trim',
    ALL: 'all',
};

/** SIMPLICITY_ENV */
export const SIMPLICITY_ENV = {
    dev: 'dev',
};

/** WORDING_FILES */
export const WORDING_FILES = {
    defaultWording: 'defaultWording',
    deWording: 'deWording',
};

/** USSA_LOGIN_URL */
export const USSA_LOGIN_URL = '{domainURL}/ussa/fed/login.ftel?partnerId={context}';
/** ONB_USSA_LOGIN_URL */
export const ONB_USSA_LOGIN_URL = '{domainURL}/business/onportal.html';
/** CONTEXT_FOR_NEW_USSA_LOGIN_URL */
export const CONTEXT_NEW_USSA_LOGIN_URL = [ 'onb' ];

/** CONTEXT_USSA_LOGIN_URL */
export const CONTEXT_USSA_LOGIN = {
    sm: '{domainURL}/mint/saml/2/unsolicited/sso?providerId=https://servicemonitor.vodafone.de/escal',
    forum: '{domainURL}/mint/saml/2/unsolicited/sso?providerId=https://forum-stage.vfd2-testnet.de/auth/saml',
    ebos: '{domainURL}/mint/saml/2/unsolicited/sso?providerId=https://ebos.calvi-insight.com',
    // onb : '{domainURL}/mint/saml/2/unsolicited/sso?providerId=https://portal.onenetbusiness2.vodafone.de',
    onbBPM: '{domainURL}/mint/saml/2/unsolicited/sso?providerId=https://bpm.onenetbusiness2.vodafone.de/',
    efax: '{domainURL}/mint/saml/2/unsolicited/sso?providerId=https://webfax.onenetbusiness2.vodafone.de/portal-sso/',
    webtt: '{domainURL}/mint/saml/2/unsolicited/sso?providerId=https://web-incident-portal-lb-pr.vodafone.de/cn',
    bc: '{domainURL}/mint/saml/2/unsolicited/sso?providerId=https://fkp-uat2.digitalactivation.de',
    onb3: '{domainURL}/mint/saml/2/unsolicited/sso?providerId=https://portal.onenetbusiness3.vodafone.de',
    onbBPM3: '{domainURL}/mint/saml/2/unsolicited/sso?providerId=https://bpm.onenetbusiness3.vodafone.de/',
    efax3: '{domainURL}/mint/saml/2/unsolicited/sso?providerId=https://webfax.onenetbusiness3.vodafone.de/portal/',
};

/** ONELOGIN_URL */
export const ONELOGIN_URL = '/meinvodafone/account';

/** EMAIL_VALIDATION_URL */
export const EMAIL_VALIDATION_URL: string =  ONELOGIN_URL + '/validate-email';

/** update email url */
export const UPDATE_EMAIL_URL: string = ONELOGIN_URL + '/email';

/** request activation code url */
export const REQUEST_ACTIVATION_CODE_URL: string = ONELOGIN_URL + '/aktivierungscode';

/** LOGIN_SERVLET_ERROR_CODES */
export const LOGIN_SERVLET_ERROR_CODES = {
    'registration-required': config.registration.route,
    'emailValidation-required': EMAIL_VALIDATION_URL,
};

/** DEFAULT_ERROR_MESSAGE */
export const DEFAULT_ERROR_MESSAGE = {
    headline: 'Entschuldigung, da ist was schiefgelaufen.',
    text: 'Bitte versuchen Sie es sp&auml;ter noch einmal.',
    class: 'error',
    icon: 'i-error-red-sml',
};

/** DATA_LOSS_WHITELIST */
export const DATA_LOSS_WHITELIST = [
    config.generalError.route,
    config.logout.timeoutUrl,
];

/** PAYMENT_SESSION_STORAGE */
export const PAYMENT_SESSION_STORAGE = {
    MOBILE_VIEW_KEY: 'mobileView',
    PAYMENT_KEY: 'topups',
};

/** PAYMENT_METHODS */
export const PAYMENT_METHODS = {
    CREDITCARD: 'CREDITCARD',
    PAYPAL: 'PAYPAL',
    GOOGLEPAY: 'GOOGLEPAY',
    DIRECTEBANKING: 'DIRECTEBANKING',
    APPLEPAY: 'APPLEPAY',
};

/** INTERNATIONAL_PHONE_NUMBER_REGEX_PASTE */
export const INTERNATIONAL_PHONE_NUMBER_REGEX_PASTE = 'internationalPhoneNumberRegexPaste';

/** PAYMENT_DELAY_TIME */
export const PAYMENT_DELAY_TIME = 10000;

/** CALLBACK */
export const CALLBACK = {
    noCallback: 'Genesys_011',
    serviceFailed: 'Genesys_012',
    fixedNet: 'acn',
    cable: 'cable',
    subscription: 'subscription',
    noMboName: 'Teilnehmer',
    updateUrl: '/hilfe-support/rueckruf.html#/neubuchung?Id=',
    deleteUrl: '/hilfe-support/rueckruf.html#/callbackLoeschen?Id=',
    callbackAccordionId: 'callback',
};

/** EMMA_MARTIN_ERRORS_IN_FORGET_PASSWORD */
export const EMMA_MARTIN_ERRORS_IN_FORGET_PASSWORD = {
    EMMA_UNIQUE_NOT_MIGRATED_VERFIED: 'emma-unique-not-migrated-verfied',
    EMMA_UNIQUE_NOT_MIGRATED_NOT_VERFIED: 'emma-unique-not-migrated-not-verfied',
    EMMA_MARTIN_NOT_UNIQUE_NOT_MIGRATED: 'emma-martin-not-unique-not-migrated',
};

/** CHECK_EMAIL_VALIDATION */
export const CHECK_EMAIL_VALIDATION = {
    STEP_NAME: {
        VALIDATE_EMAIL_STEP: 'validate-email',
    },
    CHECK_EMAIL_STEP_NAME: {
        VALIDATE_EMAIL_COMPONENT: 'validate-email',
        RESEND_CONFIRMATION_EMAIL: 'resend email confirmation',
        CHANGE_EMAIL: 'change email',
        ERROR_MESSAGE: 'error-message',
    },
};

/** KABEL_MAIL_DOMAIN */
export const KABEL_MAIL_DOMAIN = '@kabelmail.de';

/** Ignore_General_Error */
export const Ignore_General_Error = 'skip-general-error';

/** seamlesslogin */
export const SEAMLESS_LOGIN = {
    COOKIES: {
        VFCT: 'vfct',
        MOBILE: 'mobile',
        MEIN_VODAFONE_SEAMLESS_APPROVAL: 'meinVodafoneSeamlessApproval',
        MDD: 'seamlessMDD',
        XNetwork: 'x-network',
    },
    SUBMIT_TAN_ERRORCODES : {
        TAN_RETRIES_REACHED: 'tan-retries-reached' ,
    },
    ERROR_HEADLINE : {
        PROCESS_EXPIRED: 'process-expired' ,
    },
    SEAMLESS_STEPS : {
    INFO : 'info' ,
    PROVIDE_TAN_STEP : 'provide-tan',
    SUBMIT_TAN : 'submitTan',
    CONFIRMATION_STEP : 'confirmation',
},
SEAMLESS_STATUS: {
    WIFI_WITH_APPROVAL : 'wifiWithApproval',
    VF_NETWORK_NO_APPROVAL : 'vfNetworkNoApproval',
},
GET_ACTIVE_MSISDN_FOR_USERS_USING_VODAFONE_NETWORK: {
    AUTH_METHOD : 'AAA' ,
    DEVICE_TYPE : 'Smartphone' ,

},

    SESSION_STORAGE: {
        SEAMLESS_LOGIN: 'SeamlessLogin',
    },
};

/** OM_STATUS */
export const OM_STATUS = {
    FAIL: 'activated',
};
/** emails no longer accessible via MyVF Login  */
export const EMAIL_BLACK_LIST = [
    '@vodafone.de',
    '@vodafonemail.de',
    '@arcor.de',
];

/** NAVIGATION_TARGET */
export const NAVIGATION_TARGET = {
    NEW_TAB : '_newTab',
};

/** MTAN */
export const MATAN_LIMIT_COUNTER = {
    COUNTER_LIMIT: 5,
    MTAN_ERROR_CODE: 'invalid-mtan',
    SESSION_NAME: {
        RESEND_MTAN: 'resendTanCounter',
        SUBMIT_MTAN: 'submitMtancounter',
    },
    ERROR_MESSAGE_DATA: {
        Resend: {
            STATUS_CODE: '401',
            ERROR_CODE: 'mtan-limit-reached-for-resend',
        },
        SUBMIT: {
            STATUS_CODE: '401',
            ERROR_CODE: 'mtan-limit-reached-for-submit',
        },
    },
};

/** MTAN_STATIC_VALUES */
export const MTAN_STATIC_VALUES = {
    VALIDATION_ERROR_CODE: 'tan-invalid',
    RESEND_ERROR_CODE: 'tan-resent',
    VALIDATION_REMAINING_TRIES: 'mTan-validation-remaining-tries',
    RESEND_REMAINING_TRIES: 'mTan-resend-remaining-tries',
    INVALID_MTAN: 'invalid-mtan',
    VALIDATION_MAXED_OUT: 'mtan-limit-reached-for-submit',
    RESEND_MAXED_OUT: 'max-resend-reached',
    USER_BLOCKED: 'user-blocked-for-mTan',
    CLOSE_Button: 'common.buttons.close.std-headline',
    CUSTOMER_ONBOARDING: 'cs-customer-onboarding.E400F_GENERAL_ERROR',
    TAN_EXPIRED: 'tan-expired',
};

/** not available static value */
export const NOT_AVAILABLE = 'NA';

/** common proccesses keys and routes */
export const COMMON_PROCESSES_KEYS: {
/** process key */
    processKey: string,

/** process route */
    route: string,
}[] = [{
    processKey: 'com.uxpsystems.mint.vodafone.process.frontend.addContract',
    route: ONELOGIN_URL +  '/erweitern',
},
{
    processKey: 'com.uxpsystems.mint.vodafone.process.frontend.demo',
    route: ONELOGIN_URL +  '/demo',
},
{
    processKey: 'com.uxpsystems.mint.vodafone.process.frontend.customerActivationCodeLetter.v1.0',
    route: ONELOGIN_URL +  '/aktivierungscode',
},
{
    processKey: 'com.uxpsystems.mint.vodafone.process.frontend.revokeAccess.v1.0',
    route: ONELOGIN_URL +  '/zugriff',
},
{
    processKey: 'com.uxpsystems.mint.vodafone.process.frontend.updateEmail.v1.0',
    route: ONELOGIN_URL +  '/email',
},
{
    processKey: 'com.uxpsystems.mint.vodafone.process.frontend.removeEmail',
    route: ONELOGIN_URL +  '/email-loeschen',
},
{
    processKey: 'com.uxpsystems.mint.vodafone.process.frontend.mergeUser.v1.0',
    route: ONELOGIN_URL +  '/zusammenlegung',
},
{
    processKey: 'com.uxpsystems.mint.vodafone.process.frontend.acceptUserInvitation.v1.0',
    route: ONELOGIN_URL +  '/einladung',
},
{
    processKey: 'com.uxpsystems.mint.vodafone.process.frontend.changeONBName.v1.0',
    route: ONELOGIN_URL +  '/benutzername_ONB',
},
{
    processKey: 'com.uxpsystems.mint.vodafone.process.frontend.validateEmail.v1.0',
    route: ONELOGIN_URL +  '/validate-email',
},
{
    processKey: 'com.uxpsystems.mint.vodafone.process.frontend.registration.v1.0',
    route: config.registration.route,
},
{
    processKey: 'com.uxpsystems.mint.vodafone.process.frontend.resetPasswordRequest.v1.0',
    route: config.forgetPassword.route,
},
{
    processKey: 'com.uxpsystems.mint.vodafone.process.frontend.usernameForgotten.v1.0',
    route: config.forgetUsername.route,
},
{
    processKey: 'com.uxpsystems.mint.vodafone.process.frontend.solsticeFirstLogin.v1.0',
    route: config.solsticeFirstLogin.route,
},
];

/** STATES */
export const UM_STATES = {
    BW: 'BW',
    NRW: 'NRW',
    HESSEN: 'HESSEN',
};
/** REMOVE CONTRACT ACCOUNT TYPES */
export const CONTRACT_ROLE = {
    DEFAULT: 'default',
    INVITEE: 'invitee',
    ADMIN: 'admin',
};
/** DE_ORION */
export const DE_ORION = 'DE_ORION';

/** LOGOUT URL */
export const LOGOUT_URL = {
    LOGOUT: '/mint/saml/logout',
    LOGIN: '/meinvodafone/account/login',
    TARGET: 'targetURL=',
};

/** forum */
export const FORUM_URL = {
    PATH : 'https://forum-stage.vfd2-testnet.de',
};
/** INTERNAL_URL_REGEX */
export const INTERNAL_URL_REGEX = '\/meinvodafone\/(service|account)';
/** FIRST_LOGIN_COOKIE */
export const
FIRST_LOGIN_COOKIE = 'ecare-LocalStore.data.firstLogin';
/** ACTIVATION_CODE_API_KEY */
export const ACTIVATION_CODE_API_KEY = '9Ex0cIFgrErDxgqxM9bwg5F52y6B7cgY';

/** CENTRAL_LANDING_PAGE_URL */
export const CENTRAL_LANDING_PAGE_URL = '/meinvodafone/services/form-redirection?documentUrl=';

/** ENTERPRISE_INV_TYPE */
export const ENTERPRISE_INV_TYPE = 'enterprise';
/** VERIFICATION_METHODS */
export enum VERIFICATION_METHODS {
    BUC = 'BUC',
    TOTP = 'TOTP'
}
export const TWO_FACTORS_AUTHENTICATION_STEP = {
    TWO_FACTORS_METHOD: 'TwoFAMethodPrompt',
    TWO_FACTORS_VERIFICATION: 'TwoFAVerificationPrompt',
};
/** FIRST_LOGIN_STEPS */
export const FIRST_LOGIN_STEPS = {
    ENTER_CONTACT_DATA: 'enter-contact-data',
    CONFIRM_DATA: 'confirm-data',
};

/** FIRST_LOGIN_2FA_SESSION */
export const
FIRST_LOGIN_SESSION_2FA = 'ecare-LocalStore.data.firstLoginTwoFA';
/** OTP_ERROR_CODES */
export const OTP_ERROR_CODES = {
    MAX_RESEND: 'max-resend-reached',
    OTP_RESENT:'otp-resent',
    MAX_ATTEMPS: 'max-attempts-reached',
    MAX_RESEND_OTP: 'max-resend-reached-otp',

};
/** VERIFICATION_METHODS_ERROR */
export const VERIFICATION_METHODS_ERROR = {
    INVALID_CODE: 'invalid-code',
};
